import axios from "axios";
import Swal from "sweetalert2";

export const instance = axios.create({
    // baseURL: "https://carservice-api.crud.uz/",
    baseURL: "https://carservice.api-crud.uz/",
    headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    }
});

export function success(index) {
    if (index !== undefined) {
        document.querySelectorAll("[data-dismiss]")[index].click()
    }
    return Swal.fire({
        position: "center",
        icon: "success",
        title: "Success",
        showConfirmButton: false,
        timer: 1000,
    })
}

// export function catchError(error) {
//     console.error(error);
//     if (error.response.status == 401) {
//         swal({
//             icon: "warning",
//             title: "Ism yoki parolda xatolik !",
//             timer: 2000
//         }).then(() => {
//             localStorage.clear()
//             if (location.pathname !== "/") {
//                 router.push("/")
//             }
//         })
//     } else if (error.response.status == 400) {
//         swal({
//             icon: "warning",
//             title: error.response.data.detail,
//             timer: 2500
//         })
//     } else if (error.response.status == 404) {
//         swal({
//             icon: "warning",
//             title: "URL manzili noto'g'ri !",
//             timer: 2000,
//         })
//     } else if (error.response.status == 422) {
//         swal({
//             icon: "warning",
//             title: "Ma'lumotlar to'liq emas !",
//             timer: 2000,
//         })
//     } else if (error.message == "Network Error") {
//         swal({
//             icon: "warning",
//             title: "Server bilan aloqa mavjud emas !",
//             timer: 2000,
//         })
//     }
// }

// token

export function login(data) {
    let params = new URLSearchParams();

    params.append("username", data.username);
    params.append("password", data.password);

    return instance
        .post("token", params)
}



export const image_link = "https://carservice.api-crud.uz/uploaded_files/"
// export const image_link = "https://carservice-api.crud.uz/uploaded_files/"